.Solved {
  opacity: 0;
  animation: eye 1s ease-in-out 0s;
  pointer-events: none;
}

@keyframes eye {
  0% {
    transform: scale(calc(9/7));
    opacity: .01;
  }
  33% {
    transform: scale(1);
    opacity: .7;
  }
  66% {
    transform: scale(1);
    opacity: .7;
  }
  100% {
    transform: scale(calc(9/7));
    opacity: .01;
  }
  /*100% {
    transform: scale(1, 1);
    opacity: 1;
  }*/
 }
