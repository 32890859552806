/*@import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');*/

.Sudoku {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 2vh;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cbf;
  /*font-variant: common-ligatures small-caps;*/
  /*font-family: 'Varela Round', sans-serif;*/
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-size: 2.2vh;
  /*background-image: url('background.jpg');*/
  background-position: center center;
  background-size: cover;
}

header {
  margin: 0;
  padding: 0;
  display: block;
  /*height: 5vh;*/
  width: 135vh;
  /*height: 0vh;*/
  max-width: 100%;
  color: #222;
  text-transform: capitalize;
}

h1 {
  display: inline-block;
  font-size: 3vh;
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: #fff;
}

.slogan {
  /*margin-left: 50vh;*/
  float: right;
  display: none;
}

.Sudoku-game {
  display: flex;
  width: 135vh;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: row;
}

.Sudoku-game-grid {
  width: 100vh;
}

.Sudoku-game-controls {
  width: 50vh;
  display: flex;
  flex-direction: column;
}

.Sudoku-game-controls-control {
  width: 100%;
}

svg {
  display: block;
}


@media only screen and (max-aspect-ratio: 1/1) {
  .Sudoku-game {
    width: 100%;
    flex-direction: column;
  }
  .Sudoku-game-grid {
    width: 100%;
  }
  .Sudoku-game-controls {
    width: 100%;
    flex-direction: row-reverse;
  }
  .Sudoku-game-controls-control {
    width: 50%;
  }
}

.Help {
  position: absolute;
  background-color: white;
  padding: 4rem 6rem;
  border-radius: .5rem;
  border: .3rem solid black;
  overflow: hidden;
}

.Help .close {
  margin: 0;
  border: 0;
  font: inherit;
  position: absolute;
  top:  0rem;
  right: 0rem;
  background-color: transparent;
  /*padding: 1rem;*/
  cursor: pointer;
  border-radius: 0 0 0 .5rem;
  font-size: 4rem;
  line-height: 4rem;
}

.Help .close:hover {
  color: red;
}

.Help h3 {
  margin: 0;
  padding: 0;
}

.Help ul {
  padding: 0;
  margin: 1.5rem 0 0;
/*  list-style-position: inside;
*/}

.Help ul > li + li {
  margin-top: 1rem;
}

.Help ul > li.strike {
  text-decoration: line-through;
}
